<template>
  <div class="container">
    <div class="divide"></div>
    <div class="tabs">
      <span @click="routerIndex()">公司新闻与董事长寄语 > </span>
      <span @click="routerList()">新闻列表 > </span>
      <span>新闻详情</span>
    </div>
    <div class="content">
      <div class="main">
        <div class="title">{{ news.title }}</div>
        <div class="info">
          <!-- <div>阅读量：{{ news.readers }}</div> -->
          <div>时间：{{ news.gmtCreate }}</div>
        </div>
        <div class="article">{{ news.intro }}</div>
        <img :src="news.coverImage" alt="" />
        <div class="article" v-for="(item, i) in content" :key="i">
          {{ item }}
        </div>
        <div class="toggle">
          <div class="previous" @click="clickLastTitle()">
            上一篇：{{ lastTitle }}
          </div>
          <div class="next" @click="clickNextTitle()">
            下一篇：{{ nextTitle }}
          </div>
        </div>
        <div class="back" @click="routerList()">返回新闻列表</div>
      </div>
      <div class="side">
        <div class="lasted">
          <p>新闻列表</p>
          <div v-for="(item, i) in newsList" :key="i" class="item">
            <img :src="item.coverImage" alt="" />
            <div class="detail" @click="detailChange(item, i)">
              <div>{{ item.title }}</div>
              <div>
                <div>{{ item.gmtCreate }}</div>
                <!-- <div>{{ item.readers }}</div> -->
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="relative">
                <p>相关推荐</p>
                <div
                    v-for="(item, i) in relativeNews"
                    :key="i"
                    class="item"
                >
                    <img :src="item.img" alt="" />
                    <div class="detail">
                        <div>{{ item.title }}</div>
                        <div>
                            <div>{{ item.time }}</div>
                            <div>{{ item.readers }}</div>
                        </div>
                    </div>
                </div>
            </div> -->
      </div>
    </div>
  </div>
</template>

<script>
// 在页面加载完成后执行
window.onload = function () {
  // 将滚动条置于顶部
  setTimeout(function () {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, 100);
};
export default {
  data() {
    return {
      news: null,
      lastedNews: [
        {
          title: "新闻名称新闻名称新闻名称新闻名称",
          content:
            "新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容",
          time: "6小时前",
          readers: "1000阅读",
          img: require("../../assets/index/t13.png"),
        },
        {
          title: "新闻名称新闻名称新闻名称新闻名称",
          content:
            "新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容",
          time: "6小时前",
          readers: "1000阅读",
          img: require("../../assets/index/t13.png"),
        },
        {
          title: "新闻名称新闻名称新闻名称新闻名称",
          content:
            "新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容",
          time: "6小时前",
          readers: "1000阅读",
          img: require("../../assets/index/t13.png"),
        },
      ],
      relativeNews: [
        {
          title: "新闻名称新闻名称新闻名称新闻名称",
          content:
            "新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容",
          time: "6小时前",
          readers: "1000阅读",
          img: require("../../assets/index/t13.png"),
        },
        {
          title: "新闻名称新闻名称新闻名称新闻名称",
          content:
            "新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容",
          time: "6小时前",
          readers: "1000阅读",
          img: require("../../assets/index/t13.png"),
        },
        {
          title: "新闻名称新闻名称新闻名称新闻名称",
          content:
            "新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容",
          time: "6小时前",
          readers: "1000阅读",
          img: require("../../assets/index/t13.png"),
        },
      ],
      // 新闻列表
      newsList: [],
      content: [],
      // 数组index(上一篇，下一篇)
      index: "",
      // 上一篇
      lastTitle: "",
      // 下一篇
      nextTitle: "",
    };
  },
  created() {
    this.news = JSON.parse(localStorage.getItem("newsDetail"));
    this.index = localStorage.getItem("index");
  },
  mounted() {
    this.requestNewsList();
    // 分割字符串
    this.splite(this.news.content);
    //  上一篇下一篇标题展示
    setTimeout(() => {
      this.titleTip();
    }, 100);
  },

  methods: {
    // 字符串分割为数组
    splite(str) {
      this.content = str.split(/[。.]/);
      // console.log(this.content);
    },
    // 调转到新闻页
    routerIndex() {
      this.$router.push("/news");
      this.$store.commit("changeIndex", 4);
    },
    //  上一篇和下一篇标题提示
    titleTip() {
      let last;
      let next;
      // 若没有前一篇
      if (this.index == 0) {
        next = Number(this.index) + 1;
        this.lastTitle = "这已是第一篇";
        this.nextTitle = this.newsList[next].title;
        // 若已到最后一篇
      } else if (this.index == Number(this.newsList.length) - 1) {
        last = Number(this.index) - 1;
        this.lastTitle = this.newsList[last].title;
        this.nextTitle = "暂无下一篇";
      } else {
        last = Number(this.index) - 1;
        next = Number(this.index) + 1;
        this.lastTitle = this.newsList[last].title;
        this.nextTitle = this.newsList[next].title;
      }
      // console.log("lastTitle:" + this.lastTitle);
      // console.log("nextTitle:" + this.nextTitle);
    },
    // 跳转到新闻列表页
    routerList() {
      this.$router.push("/newsList");
      this.$store.commit("changeIndex", 4);
      this.$store.commit("changeTheme", true);
    },
    // 点击上一篇标题
    clickLastTitle() {
      let last = Number(this.index) - 1;
      if(last<0){
        return
      }else{
        this.news = this.newsList[last]
        localStorage.setItem('newsDetail',JSON.stringify(this.newsList[last]))
        localStorage.setItem('index',last)
        this.index = last
        this.splite(this.news.content);
        this.titleTip();
      }
      // console.log(last);
    },
    // 点击下一篇标题
    clickNextTitle() {
      let next = Number(this.index) + 1;
      if(next >= Number(this.newsList.length)){
        return
      }else{
        this.news = this.newsList[next]
        localStorage.setItem('newsDetail',JSON.stringify(this.newsList[next]))
        localStorage.setItem('index',next)
        this.index = next
        this.splite(this.news.content);
        this.titleTip();
      }

      // console.log(next);
    },
    // 更换详情信息
    detailChange(item, i) {
      localStorage.setItem("newsDetail", JSON.stringify(item));
      localStorage.setItem("index", i);
      this.index = i;
      console.log(localStorage.getItem("index"));
      this.news = item;
      this.splite(item.content);
      //  上一篇下一篇标题展示
      this.titleTip();
    },
    // 请求新闻列表
    requestNewsList() {
      let param = {
        pageNum: 1,
        pageSize: 9,
      };
      this.$post("/web/new/pageList", param).then((res) => {
        // 主要参数
        if (res.code == 1) {
          // console.log(res.data);
          this.newsList = res.data.list;
        } else {
          console.log("异常");
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.divide {
  // height: 1rem;
  box-shadow: 0px 2px 6px 0px rgba(219, 219, 219, 0.5);
  margin: 0;
}
/* // 导航条 */
.tabs {
  width: 100%;
  height: 48px;
  background: #ffffff;
  box-shadow: 0px 2px 6px 0px rgba(219, 219, 219, 0.5);
  line-height: 48px;
  font-size: 0.2rem;
  padding-top: 8px;
  margin-top: 1.1rem;
  span:nth-child(1) {
    margin-left: 24px;
    color: #a3a3a3;
    cursor: pointer;
  }
  span:nth-child(2) {
    color: #a3a3a3;
    cursor: pointer;
  }
  span:nth-child(3) {
    color: #565656;
    cursor: pointer;
  }
}
.content {
  display: flex;
  justify-content: space-between;
  padding: 0.6rem 1.6rem 0.68rem 1.55rem;
}

.main {
  width: 10.34rem;
}
.title {
  font-size: 0.48rem;
  font-weight: 500;
  color: #000000;
}
.info {
  display: flex;
  font-size: 0.18rem;
  color: #a0a0a0;
  margin: 0.58rem 0;
}
.info > div {
  margin-right: 0.46rem;
}
.article {
  font-size: 0.28rem;
  font-weight: 400;
  color: #333333;
  line-height: 52px;
  text-indent: 2em;
}
.main > img {
  width: 100%;
  margin: 0.48rem 0;
}
.toggle {
  display: flex;
  justify-content: space-between;
  font-size: 0.22rem;
  color: #127bf2;
  margin: 0.46rem 0 0.6rem;
  cursor: pointer;
}
.back {
  width: 6.6rem;
  height: 0.52rem;
  background: #127bf2;
  border-radius: 0.26rem;
  text-align: center;
  line-height: 0.52rem;
  color: white;
  font-size: 0.2rem;
  margin: auto;
  cursor: pointer;
}

.side {
  width: 4.83rem;
}
.side p {
  font-size: 0.24rem;
  font-weight: 600;
  color: #272727;
  margin-bottom: 0.15rem;
}
.item {
  display: flex;
  margin-top: 0.25rem;
}
.item > img {
  width: 1.68rem;
  height: 1.08rem;
}
.detail {
  width: 2.98rem;
  height: 1.08rem;
  margin-left: 0.25rem;
  position: relative;
  cursor: pointer;
}
.item .detail:hover div {
  color: #127bf2;
}
.detail > div:nth-of-type(1) {
  font-size: 0.24rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #3d3d3d;
}
.detail > div:nth-of-type(2) {
  // width: 60%;
  display: flex;
  justify-content: space-between;
  font-size: 0.2rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #a0a0a0;
  position: absolute;
  bottom: 0;
}
.relative {
  margin-top: 0.6rem;
}
</style>
